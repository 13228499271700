import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import BlogPost from "../components/BlogPost/BlogPost";
import Footer from "../components/Footer/Footer";

const BlogPostTemplate = ({ data: { post } }) => {
  return (
    <>
      <Seo post={post} />
      <Helmet
        htmlAttributes={{
          lang: "en-US",
        }}
      />
      <Layout>
        <Header />
        <BlogPost post={post} />
        <Footer />
      </Layout>
    </>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      blogSinglePost {
        author {
          firstName
          lastName
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                height: 302
                width: 1920
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
          altText
        }
      }
      uri
      seo {
        canonical
        cornerstone
        focuskw
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        title
        twitterTitle
        schema {
          raw
        }
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              fluid {
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
        opengraphImage {
          localFile {
            childImageSharp {
              fluid {
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
      }
      categories {
        nodes {
          name
          uri
        }
      }
      author {
        node {
          firstName
          lastName
          nicename
          nickname
          uri
          description
        }
      }
      asignPromoSlotsOnPosts {
        choosePromoSlots {
          ... on WpPromoSlot {
            id
            promoSlotsFields {
              promoSlotsGroup {
                chooseBlogPost {
                  ... on WpPost {
                    id
                    excerpt
                    content
                    title
                    date(formatString: "MMMM DD, YYYY")
                    featuredImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                              height: 500
                              width: 600
                              transformOptions: { cropFocus: CENTER }
                            )
                          }
                        }
                        altText
                      }
                    }
                    uri
                    categories {
                      nodes {
                        name
                        uri
                        id
                      }
                    }
                    author {
                      node {
                        firstName
                        lastName
                        nicename
                        nickname
                        uri
                      }
                    }
                  }
                }
                choosePage {
                  ... on WpPage {
                    id
                    featuredImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                              height: 500
                              width: 600
                              transformOptions: { cropFocus: CENTER }
                            )
                          }
                        }
                        altText
                      }
                    }
                  }
                }
                promoSlotType
                content {
                  copy
                  link {
                    target
                    title
                    url
                  }
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import "./featuredImage.scss";

const FeaturedImage = ({ image, title }) => {
  return (
    <div className="featuredImage">
      {image ? (
        <GatsbyImage
          image={image.node.localFile.childImageSharp.gatsbyImageData}
          alt={`${title}`}
        />
      ) : (
        <StaticImage
          className="placeholder-image article-image"
          src="../../../../assets/images/placeholder.png"
          alt="NYAS - Placeholder blog image"
        />
      )}
    </div>
  );
};

export default FeaturedImage;

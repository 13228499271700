/* eslint-disable react/no-danger */
import React from "react";
import Spacer from "../../../Spacer/Spacer";
import ArticleHeader from "../articleHeader/articleHeader";
import ArticlePromoPanel from "../articlePromoPanel/articlePromoPanel";
import ArticleAuthorPanel from "../articleAuthorPanel/articleAuthorPanel";
import "./articleContent.scss";

const ArticleContent = ({
  title,
  content,
  date,
  author,
  categories,
  promoSlot,
  customAuthor,
}) => {
  let authorToShow = "";
  if (
    customAuthor &&
    customAuthor.author &&
    (customAuthor.author.firstName || customAuthor.author.lastName)
  )
    authorToShow = `${
      customAuthor.author.firstName ? customAuthor.author.firstName : ""
    } ${customAuthor.author.lastName ? customAuthor.author.lastName : ""}`;
  else
    authorToShow = `${author.node.firstName ? author.node.firstName : ""} ${
      author.node.lastName ? author.node.lastName : ""
    }`;

  return (
    <div className="container">
      <Spacer size={50} mobileSize={50} />
      <div className="row">
        <div className="col-12 col-lg-8">
          <ArticleHeader
            title={title}
            date={date}
            author={authorToShow}
            categories={categories}
          />
          <div
            className="content article-content content-wrapper"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <ArticleAuthorPanel author={author} />
        </div>
        <div className="col-12 col-lg-4">
          <ArticlePromoPanel data={promoSlot} />
        </div>
      </div>
      <Spacer size={150} mobileSize={100} />
    </div>
  );
};

export default ArticleContent;

import React from "react";
import uniqueId from "lodash/uniqueId";
import IsrLabel from "../../../IsrLabel/IsrLabel";
import { Link } from "gatsby";
import "./articleHeader.scss";

const ArticleHeader = ({ title, date, author, categories }) => {
  return (
    <div className="article-header">
      <h1 className="h3">{title}</h1>
      <div className="article-meta">
        <div className="meta-info">
          <span className="date">{date}</span>
          <span className="author">{author}</span>
        </div>
        {categories && (
          <div className="categories">
            {categories.nodes.map((category, index) => (
              <IsrLabel
                variant="secondary"
                size="sm"
                color="grey"
                key={uniqueId("ba_isr_")}
              >
                <Link to={category.uri} title={category.name}>
                  {category.name}
                </Link>
              </IsrLabel>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleHeader;

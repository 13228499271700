import React from "react";
import PromoSlotCard from "../../../PromoSlotsSection/components/promoSlotsCard/PromoSlotCard";
import PromoSlotCardCustomContent from "../../../PromoSlotsSection/components/promoSlotCardCustomContent/promoSlotCardCustomContent";
import "./articlePromoPanel.scss";

const ArticlePromoPanel = ({ data }) => {
  return (
    data?.length > 0 && (
      <div className="article-promo-panel">
        {data?.map((promoSlot) => {
          const slotType =
            promoSlot.promoSlotsFields.promoSlotsGroup.promoSlotType;
          const content =
            promoSlot.promoSlotsFields.promoSlotsGroup.chooseBlogPost;
          if (slotType === "blog_post" && content) {
            return <PromoSlotCard key={promoSlot.id} post={content} />;
          }
          if (slotType === "custom") {
            return (
              <PromoSlotCardCustomContent
                key={promoSlot.id}
                data={promoSlot.promoSlotsFields.promoSlotsGroup.content}
              />
            );
          }
        })}
      </div>
    )
  );
};

export default ArticlePromoPanel;

import React from "react";
import FeaturedImage from "./components/featuredImage/featuredImage";
import ArticleContent from "./components/articleContent/articleContent";
import "./BlogPost.scss";

const BlogPost = ({
  post: {
    title,
    featuredImage,
    content,
    date,
    author,
    categories,
    asignPromoSlotsOnPosts: { choosePromoSlots },
    blogSinglePost,
  },
}) => {
  return (
    <article>
      <FeaturedImage image={featuredImage} title={title} />
      <ArticleContent
        title={title}
        content={content}
        date={date}
        author={author}
        categories={categories}
        promoSlot={choosePromoSlots}
        customAuthor={blogSinglePost}
      />
    </article>
  );
};

export default BlogPost;
